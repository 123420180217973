<template>
    
    <header class="fixed top-0 left-0 w-full bg-white shadow-md z-50">
        <div class="flex flex-row gap-10 px-6 items-center justify-between py-3">
            <!--Logo-->
            <div class="flex">
                <img class="w-[125px] h-[17px]" src="@/assets/images/logo.svg" alt="Logo">
            </div>
            <!--Button-->
            <div class="flex">
                <button class="bg-primary text-white px-4 py-2 rounded-[29px] font-semibold" @click="navigateToForm">
                    Get the App
                </button>
            </div>
        </div>
        <!--Separator-->
        <div class="border-t border-solid border-gray-100"></div>
    </header>
</template>

<script>
export default{
    name: 'landingHeader',
    methods: {
    navigateToForm() {
      this.$emit('navigate-to-form');
    }
  }
}
</script>

<style>
</style>