<template>
      <div v-if="!showDownload">
        <div class="p-6 bg-whiteSmoke rounded-[16px] w-[287px]">
          <div class="flex flex-col gap-4 items-left" >
              <div class="text-blackBg text-[21px] w-[230px] font-bold ">
                  Select your business type
              </div>
              <!-- Selectors -->
              <div class="flex flex-col gap-4 mb-6 items-left">
                  <button
                  v-for="type in businessTypes"
                  :key="type.id"
                  @click="selectType(type.id)"
                  :class="[
                      'flex items-center w-[239px] h-[52px] justify-between px-5 rounded-lg transition-colors duration-300',
                      selectedType === type.id
                      ? 'bg-blutTint text-blackBg border border-solid border-black'
                      : 'bg-white text-blackBg border border-solid border-gray'
                  ]"
                  >
                  <span class="flex-1 text-left">{{ type.name }}</span>
                  <span>
                      <div v-if="selectedType != type.id" class="w-[22px] h-[22px] border border-solid rounded-2xl"></div>
                      <div v-else>
                          <div class="w-[22px] h-[22px] border border-solid border-black rounded-2xl flex items-center justify-center">
                              <div class="w-[11px] h-[11px] bg-black rounded-2xl"></div>
                          </div>
                      </div>
                  </span>
                  </button>
              </div>
          </div>
          <!-- Submit Button -->
          <div class="flex">
            <button class="bg-primary text-white w-[239px] px-5 py-[18px] rounded-[29px] text-semibold" @click="submit">
                <div class="flex flex-row justify-between items-center">
                    <span>Get the App</span>
                    <div>
                        <img src="../../assets/images/up-arrow.svg" alt="">
                    </div>
                </div>
            </button>
          </div>
      </div>
    </div>
    <div v-else >
      <landing-download />
    </div>
  </template>
  
  <script>
  import landingDownload from './Download.vue';
  export default {
    name: 'LandingForm',
    components:{
      landingDownload
    },
    data() {
      return {
        businessTypes: [
          { id: 1, name: 'Restaurant' },
          { id: 2, name: 'Hotel' },
          { id: 3, name: 'Catering' },
          { id: 4, name: 'Hospital' },
        ],
        selectedType: null,
        showDownload: false,
      };
    },
    methods: {
      selectType(id) {
        this.selectedType = id;
      },
      submit() {
        if(this.selectedType!=null)
      {
        this.showDownload = true;
      }
      },
      
    },
  };
  </script>
  <style>
</style>
  