<template>
    <div class="flex flex-col items-center bg-whiteSmoke pt-8 pb-6 rounded-[16px] gap-4">
        <!--Text-->
        <div class="w-[335px] text-center text-[21px] font-bold">Welcome to <span class="text-primary">OneOrder.</span> Download the app now</div>
        <!--Button Apple Store-->
        <div>
            <button  @click="navigateToStore('ios')">
                <img class="w-[208px]" src="../../assets/images/appleStore.webp" alt="">
            </button>
        </div>
        <!--Button Google Play-->
        <div>
            <button @click="navigateToStore('google')">
                <img class="w-[208px]" src="../../assets/images/googlePlay.webp" alt="">
            </button>
        </div>
    </div>
</template>

<script>
export default{
    name: 'landingDownload',
    methods: {
    navigateToStore(os) {
        if(os=='google')
        {
            window.open('https://play.google.com/store/apps/details?id=com.oneorder.oneorder', '_blank');
        }
        else if(os=='ios')
        {
            window.open('https://apps.apple.com/eg/app/oneorder/id1591496287', '_blank');
        }
      
    },
  },
}
</script>

<style>
</style>