<template>
    <div class="flex flex-col items-center">
        <div class="flex flex-col gap-[80px]">
            <div class="flex flex-col gap-8">
                <div class="flex flex-col gap-6 items-center">
                    <!--Title-->
                    <div class="flex flex-col items-center text-4xl leading-10 text-black font-bold">
                        <div>Your One-Stop</div>
                        <div>Food Service</div>
                        <div>Business Supplier.</div>
                    </div>
                    <!--Subtitle-->
                    <div class="flex flex-col items-center text-base text-black">
                        <div>Designed for restaurants, hotels & catering.</div>
                        <div>Streamlined orders, smarter decisions.</div>
                    </div>
                    <!--Button-->
                    <div class="flex">
                        <button class="bg-primary text-white px-[77.5px] py-4 rounded-[29px] text-semibold" @click="navigateToForm">Get the App</button>
                    </div>
                </div>
                <!--Image-->
                <div>
                    <img src="../../assets/images/landingScreen01.webp" alt="">
                </div>
            </div>
            <div class="flex flex-col items-center gap-7">
                <!--Partner Text-->
                <div class="flex flex-col items-center text-lg">
                <div>WE ARE PARTNERED WITH</div>
                <div>INDUSTRY LEADERS,</div>
                <div>here's a few.</div>
                </div>
                <!--Partner carousel-->
                <div class="flex flex-col items-center">
                    <landing-logo-carousel :images="clientImages" directory="/assets/images/clients/laptop/" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import landingLogoCarousel from './LogoCarousel.vue';
export default{
    name: 'landingHero',
    components:{
        landingLogoCarousel
    },
    data()
    {
        return{
            clientImages: [
        {
          id: 1,
          src: "chowking.png",
          alt: "chowking",
        }, 
        {
          id: 2,
          src: "gatspy.png",
          alt: "gatspy",
        },   
        {
          id: 3,
          src: "josette.png",
          alt: "josette",
        },
        {
          id: 4,
          src: "mamazonia.png",
          alt: "mamazonia",
        },
        {
          id: 5,
          src: "texas.png",
          alt: "mamazonia",
        },

        // {
        //   id: 1,
        //   src: "al_alamein_hotel.webp",
        //   alt: "Al Alamein Hotel",
        // },
        // {
        //   id: 2,
        //   src: "ayamena.webp",
        //   alt: "Ayamena",
        // },
        // {
        //   id: 3,
        //   src: "barbar.webp",
        //   alt: "Barbar",
        // },
        // {
        //   id: 4,
        //   src: "beja.webp",
        //   alt: "Beja",
        // },
        // {
        //   id: 5,
        //   src: "bistro.webp",
        //   alt: "Bistro",
        // },
        // {
        //   id: 6,
        //   src: "blaze.webp",
        //   alt: "Blaze",
        // },
        // {
        //   id: 7,
        //   src: "bosporus.webp",
        //   alt: "Bosporus",
        // },
        // {
        //   id: 8,
        //   src: "boulevard.webp",
        //   alt: "Boulevard",
        // },
        // {
        //   id: 9,
        //   src: "buffalo_burger.webp",
        //   alt: "Buffalo Burger",
        // },
        // {
        //   id: 10,
        //   src: "caracas.webp",
        //   alt: "Caracas",
        // },
        // {
        //   id: 11,
        //   src: "crave.webp",
        //   alt: "Crave",
        // },
        // {
        //   id: 12,
        //   src: "crimson.webp",
        //   alt: "Crimson",
        // },
        // {
        //   id: 13,
        //   src: "crowne_plaza.webp",
        //   alt: "Crowne Plaza",
        // },
        // {
        //   id: 14,
        //   src: "em_sherif.webp",
        //   alt: "Em Sherif",
        // },
        // {
        //   id: 15,
        //   src: "fairmont.webp",
        //   alt: "Fairmont",
        // },
        // {
        //   id: 16,
        //   src: "four_seasons.webp",
        //   alt: "Four Seasons",
        // },
        // {
        //   id: 17,
        //   src: "fuego.webp",
        //   alt: "Fuego",
        // },
        // {
        //   id: 18,
        //   src: "hyatt_regency.webp",
        //   alt: "Hyatt Regency",
        // },
        // {
        //   id: 19,
        //   src: "jw_marriott.webp",
        //   alt: "JW Marriott",
        // },
        // {
        //   id: 20,
        //   src: "kazoku.webp",
        //   alt: "Kazoku",
        // },
        // {
        //   id: 21,
        //   src: "le_meridien.webp",
        //   alt: "Le Meridien",
        // },
        // {
        //   id: 22,
        //   src: "lexies.webp",
        //   alt: "Lexies",
        // },
        // {
        //   id: 23,
        //   src: "loft.webp",
        //   alt: "Loft",
        // },
        // {
        //   id: 24,
        //   src: "longhorn.webp",
        //   alt: "Longhorn",
        // },
        // {
        //   id: 25,
        //   src: "marriott.webp",
        //   alt: "Marriott",
        // },
        // {
        //   id: 26,
        //   src: "movenpick.webp",
        //   alt: "Movenpick",
        // },
        // {
        //   id: 27,
        //   src: "nisantasi.webp",
        //   alt: "Nisantasi",
        // },
        // {
        //   id: 28,
        //   src: "novotel.webp",
        //   alt: "Novotel",
        // },
        // {
        //   id: 29,
        //   src: "ovio.webp",
        //   alt: "Ovio",
        // },
        // {
        //   id: 30,
        //   src: "pier_88.webp",
        //   alt: "Pier 88",
        // },
        // {
        //   id: 31,
        //   src: "rixos.webp",
        //   alt: "Rixos",
        // },
        // {
        //   id: 32,
        //   src: "royal_maxim_palace_kempinski.webp",
        //   alt: "Royal Maxim Palace Kempinski",
        // },
        // {
        //   id: 33,
        //   src: "sachi.webp",
        //   alt: "Sachi",
        // },
        // {
        //   id: 34,
        //   src: "savoy.webp",
        //   alt: "Savoy",
        // },
        // {
        //   id: 35,
        //   src: "sky_rim.webp",
        //   alt: "Sky Rim",
        // },
        // {
        //   id: 36,
        //   src: "steigenberger.webp",
        //   alt: "Steigenberger",
        // },
        // {
        //   id: 37,
        //   src: "stregis.webp",
        //   alt: "St Regis",
        // },
        // {
        //   id: 38,
        //   src: "the_nile_ritz_carlton_cairo.webp",
        //   alt: "The Nile Ritz Carlton Cairo",
        // },
        // {
        //   id: 39,
        //   src: "shifa.webp",
        //   alt: "Shifa Hospital",
        // },
        // {
        //   id: 40,
        //   src: "elfouad.webp",
        //   alt: "El Fouad",
        // },
        // {
        //   id: 41,
        //   src: "cleopatra_hospitals.webp",
        //   alt: "Cleopatra Hospitals",
        // },
        // {
        //   id: 42,
        //   src: "as_salam.webp",
        //   alt: "As Salam",
        // },
        // {
        //   id: 43,
        //   src: "burger_king.webp",
        //   alt: "Burger King",
        // },
      ],
        }
    },
    methods: {
    navigateToForm() {
      this.$emit('navigate-to-form');
    }
  }
}
</script>

<style>
</style>