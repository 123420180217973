<template>
   <div>
        <div class="flex flex-col gap-y-[80px]" :style="{ fontFamily: 'Montserrat' }">
              <landing-header @navigate-to-form="scrollToForm"/>
              <div>
                <landing-hero @navigate-to-form="scrollToForm" class="pt-[160px]" />
                <landing-cards @navigate-to-form="scrollToForm" />
                <div class="flex flex-col gap-[40px] px-10 ">
                    <landing-form-section ref="formSection"/>
                    <landing-footer />
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import landingHeader from '@/components/landingPage/Header.vue'
import landingHero from '@/components/landingPage/Hero.vue'
import landingCards from '@/components/landingPage/Cards.vue'
import landingFormSection from '@/components/landingPage/FormSection.vue'
import landingFooter from '@/components/landingPage/Footer.vue'
export default{
    name: 'LandingPage',
    components: {
        landingHeader,
        landingHero,
        landingCards,
        landingFormSection,
        landingFooter
    },
    methods:{
        scrollToForm() {
      if (this.$refs.formSection && this.$refs.formSection.scrollToInnerForm) {
        this.$refs.formSection.scrollToInnerForm();
      } else {
        console.error('formSection ref or scrollToInnerForm method not found');
      }
    },
    }
}
</script>

<style>

html {
  scroll-behavior: smooth;
}
</style>