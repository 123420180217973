<template>
    <div class="flex flex-col bg-white py-6 px-6 gap-4 rounded-[22px] w-[335px]">
        <div class=" w-[40px] h-[40px] items-center justify-center">
            <div
                :key="id"
                @mouseover="lottieMouseOver(id)"
            >
            <lottie-player
                :ref="id"
                :src="icon"
                background="transparent"
                speed="1"
                class="items-center w-[50px] h-[50px]"
                :autoplay="true"
                :loop = "true"
            ></lottie-player>
            </div> 
        </div>
        <div class="text-[22px] font-bold">{{ title }}</div>
        <div class=" max-w-[270px] text-lg">{{ description }}</div>
    </div>
</template>

<script>
export default{
    name: 'landingCard',
    props:{
        icon: String,
        title: String,
        description: String,
        id: Number,
    },
    methods: {
    lottieMouseOver(lottieId) {
      const refName = `lottie-${lottieId}`;
      const lottie = this.$refs[refName];
      if (lottie) {
        lottie.seek(0);
        lottie.play();
      } else {
        console.warn(`Lottie ref "${refName}" not found.`);
      }
    },
  },
}
</script>

<style>
</style>