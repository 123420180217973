<template>
    <div class="flex flex-col gap-[40px]">
        <div class="flex flex-col pt-[80px] items-center px-5 gap-5">
            <div class="flex flex-col">
                <div class="flex flex-col gap-[21px] pb-[80px] items-center">
                    <!--Title-->
                    <div class="flex items-center text-center w-[330px] text-lg">POWERING YOUR BUSINESS WITH TOP BRANDS YOU KNOW AND TRUST.</div>
                    <!--Carousel-->
                    <div class="flex flex-col items-center">
                        <landing-logo-carousel :images="suppliersImages" directory= "/assets/images/suppliers/laptop/" />
                    </div>
                </div>
                <!--Form Section-->
                <div ref="innerFormSection" class="pt-[70px]">
                    <div class="p-x-5 flex items-center justify-center">
                        <div class="flex flex-col items-center bg-black rounded-[32px] gap-12">
                            <div class="flex flex-col gap-6 items-center ">
                                <!--Title-->
                                <div class="felx items-center justify-center text-center w-[391px] text-white text-4xl pt-10 pl-6 pr-[30px] font-bold">Join the new era of food supplies.</div>
                                <div>
                                    <landing-form />
                                </div>
                            </div>
                            <div class="flex w-[290px]">
                                <img src="../../assets/images/landingScreen02.webp" alt="screen2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <!--Seperator-->
         <div class="border border-solid border-gray-100"></div>
    </div>
</template>

<script>
import landingForm from '@/components/landingPage/landingForm.vue'
import landingLogoCarousel from './LogoCarousel.vue';
export default{
    name: 'landingFormSection',
    components: {
        landingForm,
        landingLogoCarousel
    },
    data()
    {
        return{
            suppliersImages: [
        {
          id: 1,
          src: "heinz.png",
          alt: "heinz",
        },
        {
          id: 2,
          src: "friloi.png",
          alt: "friloi",
        },
        {
          id: 3,
          src: "superchef.png",
          alt: "superchef",
        },
        {
          id: 4,
          src: "seara.png",
          alt: "seara",
        },
        {
          id: 5,
          src: "monin.png",
          alt: "monin",
        },
        // {
        //   id: 1,
        //   src: "americana.webp",
        //   alt: "Americana",
        // },
        // {
        //   id: 2,
        //   src: "baresa.webp",
        //   alt: "Baresa",
        // },
        // {
        //   id: 3,
        //   src: "calgonit.webp",
        //   alt: "Calgonit",
        // },
        // {
        //   id: 4,
        //   src: "cooks.webp",
        //   alt: "Cooks",
        // },
        // {
        //   id: 5,
        //   src: "dixie_mills.webp",
        //   alt: "Dixie Mills",
        // },
        // {
        //   id: 6,
        //   src: "dolphin.webp",
        //   alt: "Dolphin",
        // },
        // {
        //   id: 7,
        //   src: "elarosa_tea.webp",
        //   alt: "El Arosa Tea",
        // },
        // {
        //   id: 8,
        //   src: "eldoha.webp",
        //   alt: "El Doha",
        // },
        // {
        //   id: 9,
        //   src: "elrashedy.webp",
        //   alt: "El Rashedy",
        // },
        // {
        //   id: 10,
        //   src: "fern.webp",
        //   alt: "Fern",
        // },
        // {
        //   id: 11,
        //   src: "fine.webp",
        //   alt: "Fine",
        // },
        // {
        //   id: 12,
        //   src: "five_stars.webp",
        //   alt: "Five Stars",
        // },
        // {
        //   id: 13,
        //   src: "harvest.webp",
        //   alt: "Harvest",
        // },
        // {
        //   id: 14,
        //   src: "heinz.webp",
        //   alt: "Heinz",
        // },
        // {
        //   id: 15,
        //   src: "hellmanns.webp",
        //   alt: "Hellmanns",
        // },
        // {
        //   id: 16,
        //   src: "imtenan.webp",
        //   alt: "Imtenan",
        // },
        // {
        //   id: 17,
        //   src: "italiano.webp",
        //   alt: "Italiano",
        // },
        // {
        //   id: 18,
        //   src: "juhayna.webp",
        //   alt: "Juhayna",
        // },
        // {
        //   id: 19,
        //   src: "kiri.webp",
        //   alt: "Kiri",
        // },
        // {
        //   id: 20,
        //   src: "lipton.webp",
        //   alt: "Lipton",
        // },
        // {
        //   id: 21,
        //   src: "mainz.webp",
        //   alt: "Mainz",
        // },
        // {
        //   id: 22,
        //   src: "mars.webp",
        //   alt: "Mars",
        // },
        // {
        //   id: 24,
        //   src: "najjar.webp",
        //   alt: "Najjar",
        // },
        // {
        //   id: 25,
        //   src: "nescafe.webp",
        //   alt: "Nescafe",
        // },
        // {
        //   id: 26,
        //   src: "nestle.webp",
        //   alt: "Nestle",
        // },
        // {
        //   id: 27,
        //   src: "nido.webp",
        //   alt: "Nido",
        // },
        // {
        //   id: 28,
        //   src: "nutella.webp",
        //   alt: "Nutella",
        // },
        // {
        //   id: 29,
        //   src: "president.webp",
        //   alt: "President",
        // },
        // {
        //   id: 30,
        //   src: "red_bull.webp",
        //   alt: "Red Bull",
        // },
        // {
        //   id: 31,
        //   src: "regina.webp",
        //   alt: "Regina",
        // },
        // {
        //   id: 32,
        //   src: "roots.webp",
        //   alt: "Roots",
        // },
        // {
        //   id: 33,
        //   src: "saluti.webp",
        //   alt: "Saluti",
        // },
        // {
        //   id: 34,
        //   src: "shady_pack.webp",
        //   alt: "Shady Pack",
        // },
        // {
        //   id: 35,
        //   src: "unilever.webp",
        //   alt: "Unilever",
        // },
        // {
        //   id: 36,
        //   src: "vitrac.webp",
        //   alt: "Vitrac",
        // },
        // {
        //   id: 37,
        //   src: "wadi_food.webp",
        //   alt: "Wadi Food",
        // },
        // {
        //   id: 38,
        //   src: "yamama.webp",
        //   alt: "Yamama",
        // },
      ],
        }
    },
    methods: {
        scrollToInnerForm() {
            const el = this.$refs.innerFormSection;
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.error('innerFormSection ref not found');
            }
        }
    }

}
</script>

<style>
</style>