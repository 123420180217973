import { render, staticRenderFns } from "./landingForm.vue?vue&type=template&id=0a159d38"
import script from "./landingForm.vue?vue&type=script&lang=js"
export * from "./landingForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports